import "../htmx"
import htmx from "htmx.org"
import "htmx.org/dist/ext/response-targets"

import application from "./components/Application"

declare global {
  interface Window {
    htmx: typeof htmx
    MSStream?: unknown
  }
}

// https://github.com/bigskysoftware/htmx/issues/2587
htmx.config.historyCacheSize = 0
htmx.config.scrollIntoViewOnBoost = false
htmx.config.defaultSwapStyle = "outerHTML"

application.start()
