import Marquee from "./Marquee"

interface DOM {
  rows: NodeListOf<HTMLElement>
}

class MarqueeManager {
  instances: Marquee[] = []
  DOM?: DOM

  constructor() {
    this.init()
  }

  setup() {
    if (this.DOM && this.DOM.rows.length) {
      for (let i = 0; i < this.DOM.rows.length; i++) {
        const row = this.DOM.rows[i]
        if (row) {
          this.instances.push(
            new Marquee({
              container: row,
              config: {
                repeat: -1,
                speed: 0.9,
                reversed: row.dataset.reversed !== undefined,
              },
            })
          )
        }
      }
    }
  }

  destroy() {
    if (this.DOM) {
      for (let i = 0; i < this.instances.length; i++) {
        this.instances[i].destroy()
      }
      this.instances = []
      this.DOM = undefined
    }
  }

  init(container = document) {
    const rows = container.querySelectorAll<HTMLElement>(".js-marquee-row")

    if (rows.length) {
      this.DOM = {
        rows,
      }

      this.instances = []

      this.setup()
    }
  }
}

export default MarqueeManager
